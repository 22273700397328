import React from 'react'
// import cx from 'classnames'

import FolksyFaceIcon from '../../images/svg/folksy-face-icon.inline.svg'

export const Loading = () => {
  return (
    <div className="animate-pulse block w-90 bg-blue-bright py-40 rounded-13 mx-auto shadow-card">
      <FolksyFaceIcon className="block mx-auto rect-loading" />
    </div>
  )
}
