import React from 'react'
import { Router } from '@reach/router'

import Layout from '../components/layout'
import PrivateRoute from '../components/private-route'

import AccountEditPage from '../client-pages/account/edit'
import NotFound from '../client-pages/404.js'

const AccountApp = () => {
  return (
    <Layout>
      <Router className="w-full shadow-none">
        <PrivateRoute path="/account/edit" component={AccountEditPage} />
        <PrivateRoute component={NotFound} default />
      </Router>
    </Layout>
  )
}

export default AccountApp
