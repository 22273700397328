import React from 'react'
import { bool, func } from 'prop-types'
import { navigate } from 'gatsby'

import { useUserbaseUserState } from '../services/userbase/userProvider'
import { useUserbaseDeckDBState } from '../services/userbase/deckDBProvider'
import { Loading } from './loading'

/**
 * @typedef PrivateRouteProps
 * @prop {any} component
 * @prop {boolean=} deckReq
 * @prop {boolean=} default
 * @prop {string=} path
 */

/**
 * @param {PrivateRouteProps} props
 */
const PrivateRoute = ({ component: Component, deckReq, ...rest }) => {
  const { ready, user } = useUserbaseUserState()
  const { deck, connected } = useUserbaseDeckDBState()

  const NOT_READY = !ready
  const READY_LOGGED_OUT = ready && !user
  const READY_LOGGED_IN_NOT_CONNECTED = ready && user && !connected
  const READY_LOGGED_IN_CONNECTED_NO_DECK = ready && user && connected && !deck

  if (NOT_READY) {
    return <Loading />
  }

  if (READY_LOGGED_OUT) {
    navigate('/login')
    return null
  }

  if (deckReq && READY_LOGGED_IN_NOT_CONNECTED) {
    return <Loading />
  }

  if (deckReq && READY_LOGGED_IN_CONNECTED_NO_DECK) {
    navigate('/')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute

PrivateRoute.propTypes = {
  component: func,
  deckReq: bool,
}
