import React from 'react'
import cx from 'classnames'
import { func, node, string } from 'prop-types'

export const Form = ({ children, className, onSubmit }) => {
  return (
    <form
      autoComplete="off"
      className={cx(
        className,
        'max-w-form w-full bg-white rounded pt-32 pb-40 px-32 sm:px-48 text-center shadow'
      )}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  )
}

Form.propTypes = {
  children: node.isRequired,
  className: string,
  onSubmit: func.isRequired,
}
