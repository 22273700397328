import React from 'react'
import cx from 'classnames'

import WarningIcon from '../../images/svg/warning-icon.inline.svg'

/**
 * @param {import('./types').InputProps} props
 */
const InputComponent = (
  { className, id, value, setValue, children, type, error, onErrorClear },
  ref
) => {
  const [focused, setFocused] = React.useState(false)

  return (
    <div className={cx(className, 'relative')}>
      <label
        className={cx(
          'flex items-center',
          'absolute top-0 left-0 h-input px-24',
          'text-left util-xs uppercase text-blue-organic',
          'pointer-events-none z-bottom',
          {
            hidden: value !== '' || focused,
          }
        )}
        htmlFor={id}
      >
        {children}
      </label>
      <input
        ref={ref}
        autoComplete="off"
        className={cx(
          'appearance-none',
          'w-full sm:min-w-312 h-input px-24',
          'util-input',
          'border-2 border-blue-organic rounded-50',
          'focus:border-blue-soft focus:shadow-outline',
          {
            'text-blue-organic': !error,
            'text-red-hot': error,
          }
        )}
        type={type || 'text'}
        id={id}
        name={id}
        value={value}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        onChange={(e) => {
          if (error && onErrorClear) {
            onErrorClear()
          }

          setValue(e.target.value)
        }}
      />
      {error && (
        <p className="text-xs font-semibold text-red-hot pt-4">
          <WarningIcon className="mr-4" aria-hidden="true" />
          {error}
        </p>
      )}
    </div>
  )
}

export const Input = React.forwardRef(InputComponent)
