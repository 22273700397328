import React from 'react'
import { navigate } from 'gatsby'

import SEO from '../../components/seo'
import { Form } from '../../components/form'
import { Button } from '../../components/button'
import { Input } from '../../components/input'
import { useUserbaseUser } from '../../services/userbase'

const AccountEditPage = () => {
  const { changePassword } = useUserbaseUser()
  const [currentPassword, setCurrentPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [error, setError] = React.useState({
    currentPassword: '',
    newPassword: '',
  })

  return (
    <>
      <SEO title="Change Password" />

      <Form
        className="mx-auto"
        onSubmit={async (e) => {
          e.preventDefault()

          const response = await changePassword({
            currentPassword,
            newPassword,
          })

          if (response.status === 200) {
            // @TODO global banner
            navigate('/')
          } else {
            if (
              [
                'CurrentPasswordMissing',
                'CurrentPasswordIncorrect',
                'PasswordAttemptLimitExceeded',
              ].includes(response.name)
            ) {
              setError({ ...error, currentPassword: response.message })
            } else {
              setError({ ...error, newPassword: response.message })
            }
          }
        }}
      >
        <h1 className="hd-xl text-blue-bright mb-24">Change Password</h1>

        <Input
          className="mb-24"
          id="password"
          type="password"
          value={currentPassword}
          setValue={setCurrentPassword}
          error={error.currentPassword}
          onErrorClear={() => {
            setError({
              ...error,
              currentPassword: '',
            })
          }}
        >
          Current or Temporary Password
        </Input>

        <Input
          className="mb-24"
          id="newPassword"
          type="password"
          value={newPassword}
          setValue={setNewPassword}
          error={error.newPassword}
          onErrorClear={() => {
            setError({
              ...error,
              newPassword: '',
            })
          }}
        >
          New Password
        </Input>

        {/* @TODO match passwords error handling */}
        <Input
          className="mb-24"
          id="passwordConfirm"
          type="password"
          value={passwordConfirm}
          setValue={setPasswordConfirm}
        >
          Confirm New Password
        </Input>

        <Button
          className="block min-w-200 mb-32"
          disabled={
            !currentPassword || !newPassword || newPassword !== passwordConfirm
          }
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </>
  )
}

export default AccountEditPage
